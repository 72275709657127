import React, { useEffect, useState } from 'react';
import './react/App.css';
import './react/w3.css';
import Button from '@mui/material/Button';
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";
import { styles } from './styles';
import { GetSize, NavigationMenu, Footer, Debug, LoadSalon, SaveSalonSettings, LoadSalonSettings, LoadLogedIn, LoadLanguage, LoadAlias, Verify, SaveLanguage, ChangeLanguage, ChangeLanguageURL, Loading, LoadClient, NeedRedraw, SaveSalon, dbURL, SaveTime, BannerImage, MyLine, LoadCardQuantity, LoadCookies, CookiesInfo, SaveCookies, BottomMenu, LoadToken, SavePage, SaveScroll, CheckToken } from './Items.js';
import { BannerTop, LoadProductNames, SaveProductNames, GetLanguage, SalonPhoto } from './Items.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMobile, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { Alert, CircularProgress, TextField } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import ReCAPTCHA from 'react-google-recaptcha'

export const Contact = (props) => {
    const navigate = useNavigate();
    let params = useParams();

    const [isBusy, setBusy] = useState(false);
    const [salon, setSalon] = useState(false);
    const [settings, setSettings] = useState(false);
    const [language, setLanguage] = useState(ChangeLanguageURL());
    const [alias, setAlias] = useState(params.alias);
    const [client, setClient] = useState(false);
    const [productNames, setProductNames] = useState([]);
    const [logedIn, setLogedIn] = useState(false);
    const [showCookies, setShowCookies] = useState(false);
    const [theme, setTheme] = useState(0);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [text, setText] = useState('');
    const [msgError, setMsgError] = useState('');
    const [isSending, setSending] = useState(false);
    const [showSendOK, setShowSendOK] = useState(false);
    const [showSendERR, setShowSendERR] = useState(false);
    const [recaptcha, setRecaptcha] = useState('');

    const [cardQuantity, setCardQuantity] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const pin = require('./react/pin.png');

    var lang = GetLanguage(language);
    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            
            if (LoadCookies() == null) {
                setShowCookies(true);
            }

            const element = document.getElementById('id-start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return () => {
        };

    }, []);

    const TokenResult = (typ, json) => {
        if (typ == true) {
            setBusy(false);
            setSalon(json.salon);
            setAlias(json.salon.alias);
            setSettings(json.settings);
            setLanguage(json.salon.language);
            setClient(json.client);
            setLogedIn(json.logged);

        } else {
            navigate('/logout');
        }
    }

    /* *******************************************************************************
            POSLAT SPRAVU
    ******************************************************************************* */
    const db_message_send = async () => {
        setSending(true);
        setShowSendOK(false);
        setShowSendERR(false);
        setMsgError('');
        var salon = LoadSalon();
        var salon_id = salon.id;

        // Ak je chybné číslo salóna - LOGOUT
        if (salon_id == undefined || salon_id == null || salon_id == 0) {
            navigate('/logout');
        }

        try {
            const response = await fetch(
                dbURL() + 'message', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: salon_id,
                    name: name,
                    email: email,
                    message: text
                })
            })

            const json = await response.json();
            setSending(false);
            Debug(json);
            if (json.ok > 0) {
                setShowSendOK(true);
            } else {
                setShowSendERR(true);
            }
        } catch (error) {
            setSending(false);
            setShowSendERR(true);
            Debug(error);
        }

    };

    const BannerTopPress = (value, token_) => {
        if (value == 1) {
            // PRIHLASENIE SA UZIVATELA -> presmerovanie na jeho salon
            SavePage(0);
            SaveScroll(0);
            //db_token(token_);
            window.location.reload();
        }

        // AKCIA Z BANNERU
        if (value == 2) {
            // ODHLASIT SA
            navigate('/logout');
        }
        if (value == 3) {
            // Moj ucet
            navigate('/' + alias + '/account');
        }
    }


    function ValidateEmail(mail) {
        // kontrola správnosti e-mailovej adresy
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(mail)) {
            return (true)
        }
        return (false)
    };

    const SendMessage = () => {
        setMsgError('');

        if (name.trim() != '') {
            if (email.trim() != '') {
                if (ValidateEmail(email) == true) {
                    if (text.trim() != '') {
                        if (recaptcha != '') {
                            // ODOSLAT SPRAVU
                            db_message_send();
                        } else {
                            setMsgError(lang.captcha_error);
                        }
                    } else {
                        // CHYBA TEXT
                        setMsgError(lang.send_err_text);
                    }
                } else {
                    // CHYBNY FORMAT EMAILU
                    setMsgError(lang.send_err_email);
                }
            } else {
                // CHYBA EMAIL
                setMsgError(lang.send_err_email);
            }
        } else {
            // CHYBA MENO
            setMsgError(lang.send_err_name);
        }
    }

    const CookiesInfoPress = (value) => {
        setShowCookies(false);
        SaveCookies(true);
    }

    const AnyReactComponent = ({ text }) => <div style={{ position: 'absolute', top: -40, left: -20, width: 40, height: 40 }}>
        <img src={pin} height={40} />
    </div>;

    const onChange = (value) => {
        Debug('Captcha', value == null ? 'NULL' : value);
        if (value == null) {
            setRecaptcha('');
        } else {
            setRecaptcha(value);
        }
    }

    return (
        <div id={'id-start'}>
            {isSmall ? null :
                <BannerTop theme={theme} chooseSalon={true} client={client} logged={client == false ? false : true} alias={alias} salon={salon} logedIn={logedIn} language={language} func={BannerTopPress.bind(this)} />
            }
            <NavigationMenu theme={theme} size={() => GetSize()} client={client} logged={client == false ? false : true} showLanguage={false} language={language} logedin={logedIn} alias={alias} salon={salon} index={2} quantity={cardQuantity} products={productNames} />
            <CheckToken alias={alias} func={TokenResult.bind(this)} />

            <div style={{ display: 'flex', flexDirection: isLarge == true ? 'row' : 'column', justifyContent: 'center', padding: 10, backgroundColor: global.theme_gray }}>
                <div style={{ display: 'flex', padding: 10, width: isLarge == true ? '50%' : '100%', justifyContent: 'center' }}>
                    {settings != false ?
                        <SalonPhoto source={settings == null ? '' : settings.photo} size={300} />
                        : null}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', padding: 10, width: isLarge == true ? '50%' : '100%', justifyContent: 'center', alignItems: isLarge == true ? 'flex-start' : 'center' }}>
                    <h2 style={{ margin: 0, letterSpacing: 5, marginBottom: 20 }}>{lang.contact_us}</h2>
                    {salon != false ?
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderLeft: '2px solid ' + global.theme_black, paddingLeft: 10 }}>
                            <p style={{ margin: 0, fontWeight: 'bold' }}>{salon.salon_name}</p>
                            <p style={{ margin: 0, fontWeight: 'bold' }}>{salon.salon_street} {salon.salon_street_number}</p>
                            <p style={{ margin: 0, fontWeight: 'bold' }}>{salon.salon_psc} {salon.salon_town}</p>
                        </div>
                        : null}
                    {salon != false ?
                        <p style={{ margin: 0, marginTop: 10 }}><FontAwesomeIcon style={{ marginRight: 19 }} icon={faMobileAlt} /><a href={'tel://' + salon.mobil} style={{ textDecoration: 'none' }}>{salon.mobil}</a></p>
                        : null}
                    {salon != false ?
                        <p style={{ margin: 0 }}><FontAwesomeIcon style={{ marginRight: 15 }} icon={faEnvelope} /><a href={'mailto://' + salon.email} style={{ textDecoration: 'none' }}>{salon.email}</a></p>
                        : null}
                </div>
            </div>

            {settings != null ?
                <div style={{ display: 'flex', flexDirection: isLarge == true ? 'row' : 'column', justifyContent: 'center', padding: 10, paddingTop: 50, paddingBottom: 50 }}>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 10, backgroundColor: global.theme_white, width: isLarge == true ? '50%' : '100%' }}>
                        <h3 style={{ margin: 0 }}>{lang.opening_hours}</h3>
                        <MyLine />
                        {settings != false ?
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >

                                <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid ' + global.theme_gray, width: 400, marginBottom: 10, justifyContent: 'center', alignItems: 'center' }}>
                                    <p style={{ margin: 0, fontWeight: 'bold', color: global.theme_dark_gray }}>{lang.day_1}:</p>
                                    <p style={{ margin: 0, marginLeft: 20 }}>{settings.hours_1}</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid ' + global.theme_gray, width: 400, marginBottom: 10, justifyContent: 'center', alignItems: 'center' }}>
                                    <p style={{ margin: 0, fontWeight: 'bold', color: global.theme_dark_gray }}>{lang.day_2}:</p>
                                    <p style={{ margin: 0, marginLeft: 20 }}>{settings.hours_2}</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid ' + global.theme_gray, width: 400, marginBottom: 10, justifyContent: 'center', alignItems: 'center' }}>
                                    <p style={{ margin: 0, fontWeight: 'bold', color: global.theme_dark_gray }}>{lang.day_3}:</p>
                                    <p style={{ margin: 0, marginLeft: 20 }}>{settings.hours_3}</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid ' + global.theme_gray, width: 400, marginBottom: 10, justifyContent: 'center', alignItems: 'center' }}>
                                    <p style={{ margin: 0, fontWeight: 'bold', color: global.theme_dark_gray }}>{lang.day_4}:</p>
                                    <p style={{ margin: 0, marginLeft: 20 }}>{settings.hours_4}</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid ' + global.theme_gray, width: 400, marginBottom: 10, justifyContent: 'center', alignItems: 'center' }}>
                                    <p style={{ margin: 0, fontWeight: 'bold', color: global.theme_dark_gray }}>{lang.day_5}:</p>
                                    <p style={{ margin: 0, marginLeft: 20 }}>{settings.hours_5}</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid ' + global.theme_gray, width: 400, marginBottom: 10, justifyContent: 'center', alignItems: 'center' }}>
                                    <p style={{ margin: 0, fontWeight: 'bold', color: global.theme_dark_gray }}>{lang.day_6}:</p>
                                    <p style={{ margin: 0, marginLeft: 20 }}>{settings.hours_6}</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid ' + global.theme_gray, width: 400, marginBottom: 10, justifyContent: 'center', alignItems: 'center' }}>
                                    <p style={{ margin: 0, fontWeight: 'bold', color: global.theme_dark_red }}>{lang.day_7}:</p>
                                    <p style={{ margin: 0, marginLeft: 20 }}>{settings.hours_7}</p>
                                </div>
                            </div>
                            : null}
                    </div>

                    {settings != false ? settings.map == true && parseFloat(settings.lat) > 0 ?
                        <div style={{ display: 'flex', padding: 10, width: isLarge == true ? '50%' : '100%', justifyContent: isLarge ? 'flex-start' : 'center' }}>
                            <div style={{ height: isLarge ? 400 : 300, width: isLarge ? 400 : 300 }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: 'AIzaSyCVLKeXod-XpjnLLg1MEbf_wO5OFc5Wv3g' }}
                                    defaultCenter={{ lat: parseFloat(settings.lat), lng: parseFloat(settings.lng) }}
                                    defaultZoom={17}
                                >
                                    <AnyReactComponent
                                        lat={settings.lat}
                                        lng={settings.lng}
                                        text="SALON"
                                    />
                                </GoogleMapReact>
                            </div>
                        </div>
                        : null : null}

                </div>
                : null}

            <div style={{ display: 'flex', padding: 20, backgroundColor: global.theme_lighter, justifyContent: 'center' }}>
                <div style={{ padding: 10, backgroundColor: global.theme_white, width: 400, borderRadius: 20, border: '1px solid ' + global.theme_dark }}>
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
                        <FontAwesomeIcon style={{ marginLeft: 10, marginRight: 30, marginTop: 5, fontSize: 45, color: global.theme_medium_gray }} icon={faEnvelope} />
                        <h4 style={{ margin: 0, textAlign: 'left' }}>{lang.write_us}</h4>
                    </div>
                    <div style={{ padding: 5 }}>
                        <TextField
                            sx={{ color: global.theme_black, width: '100%' }}
                            label={lang.name_surname}
                            variant="outlined"
                            value={name}
                            onInput={e => setName(e.target.value)}
                        />
                    </div>
                    <div style={{ padding: 5 }}>
                        <TextField
                            sx={{ color: global.theme_black, width: '100%' }}
                            label={lang.email_answer}
                            variant="outlined"
                            value={email}
                            onInput={e => setEmail(e.target.value)}
                        />
                    </div>
                    <div style={{ padding: 5 }}>
                        <TextField
                            sx={{ color: global.theme_black, width: '100%' }}
                            label={lang.email_text}
                            variant="outlined"
                            multiline
                            rows={8}
                            value={text}
                            onInput={e => setText(e.target.value)}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ReCAPTCHA
                            sitekey="6LcBgIQeAAAAAMk_qe_tHfZjpY0vOuhtfxP1RtgP"
                            onChange={onChange}
                        />
                    </div>
                    {isSending == true ?
                        <CircularProgress />
                        :
                        msgError != '' ?
                            <div style={{ padding: 5 }}>
                                <Alert severity="error">{msgError}</Alert>
                            </div>
                            : null
                    }
                    {showSendOK == true ?
                        <Alert severity="success">{lang.send_msg_ok}</Alert>
                        : null}
                    {showSendERR == true ?
                        <Alert severity="error">{lang.send_msg_err}</Alert>
                        : null}


                    <div style={{ padding: 15 }}>
                        <Button onClick={() => SendMessage()} style={{ backgroundColor: global.theme_black, width: 250 }} variant="contained">{lang.send_msg}</Button>
                    </div>
                </div>
            </div>

            {showCookies == true ?
                <CookiesInfo lang={lang} salon={salon} func={CookiesInfoPress.bind(this)} />
                : null}

            <BottomMenu language={language} salon={salon}/>
            <Footer language={language} islogged={logedIn} />
        </div >
    )

}
