/*
*
* =================================================================================================================
    UCET ZAKAZNIKA
    - zmena údajov
    - absolvované ošetrenia
    - zakúpené produkty
    - história nákupov
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';
import './react/App.css';
import './react/w3.css';
import Button from '@mui/material/Button';
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";

import { setLanguage } from './store/data.js';
import { GetSize, NavigationMenu, Footer, Debug, LoadSalon, SaveSalonSettings, LoadSalonSettings, LoadLogedIn, LoadLanguage, LoadAlias, Verify, SaveLanguage, ChangeLanguage, ChangeLanguageURL, Loading, LoadClient, NeedRedraw, SaveSalon, dbURL, SaveTime, SaveClient, MyLine, NavigationBar, PaginationBar, ValidateEmail, LoadCardQuantity, BonusInfo, LoadCookies, CookiesInfo, SaveCookies, LoadToken, GetImageLink, CheckToken, IsEmpty, nonEmail } from './Items.js';
import { BannerTop, LoadProductNames, SaveProductNames, GetLanguage, GetStatus, GetStatusColor } from './Items.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faEdit, faEnvelope, faMobile, faMobileAlt, faShoppingBasket, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import Dialog from '@mui/material/Dialog';
import { Backdrop, CircularProgress, IconButton, Paper, TextField } from '@mui/material';
import { Alert } from '@mui/material';
import { styles } from './styles';

export const Account = (props) => {
    const navigate = useNavigate();
    let params = useParams();

    const [isBusy, setBusy] = useState(true);
    const [isUpdating, setUpdating] = useState(false);
    const [isChanging, setChanging] = useState(false);

    const [salon, setSalon] = useState(false);
    const [settings, setSettings] = useState(false);
    const [language, setLanguage] = useState(0);
    const [alias, setAlias] = useState(params.alias);
    const [client, setClient] = useState(false);
    const [productNames, setProductNames] = useState([]);
    const [logedIn, setLogedIn] = useState(false);
    const [editClient, setEditClient] = useState(false);
    const [showCookies, setShowCookies] = useState(false);
    const [theme, setTheme] = useState(0);

    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState(false);
    const [surname, setSurname] = useState('');
    const [surnameErr, setSurnameErr] = useState(false);
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState(false);
    const [mobil, setMobil] = useState('');
    const [mobilErr, setMobilErr] = useState(false);
    const [street, setStreet] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [psc, setPSC] = useState('');
    const [town, setTown] = useState('');
    const [clientErr, setClientErr] = useState('');

    const [menu, setMenu] = useState(0);
    const [services, setServices] = useState([]);
    const [servicesPage, setServicesPage] = useState(0);
    const [servicesMax, setServicesMax] = useState(0);
    const [servicesFiltered, setServicesFiltered] = useState([]);

    const [products, setProducts] = useState([]);
    const [productsPage, setProductsPage] = useState(0);
    const [productsMax, setProductsMax] = useState(0);
    const [productsFiltered, setProductsFiltered] = useState([]);
    const [productsDetails, setProductsDetails] = useState(false);
    const [showProductsDetails, setShowProductsDetails] = useState(false);
    const [description, setDescription] = useState([]);

    const [orders, setOrders] = useState([]);
    const [ordersPage, setOrdersPage] = useState(0);
    const [ordersFiltered, setOrdersFiltered] = useState([]);
    const [ordersMax, setOrdersMax] = useState(0);

    const [cardQuantity, setCardQuantity] = useState(0);
    const [showBonusInfo, setShowBonusInfo] = useState(false);

    const [showChangePassword, setShowChangePassword] = useState(false);
    const [password1, setPassword1] = useState('');
    const [password1Err, setPassword1Err] = useState(false);
    const [password2Err, setPassword2Err] = useState(false);
    const [password2, setPassword2] = useState('');
    const [passwordErr, setPasswordErr] = useState('');

    const voucher_sk = require('./react/voucher_sk.png'); // 300 x 134
    const voucher_cz = require('./react/voucher_cz.png'); // 300 x 134
    const img_service = require('./react/service.png');
    const logo_black = require('./react/logo_ie.png'); // 610 x 225, 152 x 56
    const logo_circle = require('./react/logo_gold_circle.png');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    var lang = GetLanguage(language);
    const infoRef = useRef(null)
    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            if (LoadCookies() == null) {
                setShowCookies(true);
            }

            const element = document.getElementById('id-start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return () => {
        };

    }, []);

    const TokenResult = (typ, json) => {
        if (typ == true) {
            var token = LoadToken();

            setSalon(json.salon);
            setAlias(json.salon.alias);
            setSettings(json.settings);
            setLanguage(json.salon.language);
            setClient(json.client);
            setLogedIn(json.logged);

            if (json.client != false) {
                db_client_data(json.salon.id, json.client == false ? 0 : json.client.id, json.salon.language, token);
            } else {
                navigate('/logout');
            }
        } else {
            navigate('/logout');
        }
    }

    /* *******************************************************************************
        UDAJE O ZAKZNIKOVI - OSETRENIA, PRODUKTY
    ******************************************************************************* */
    const db_client_data = async (salon_id, client_id, language_, token) => {
        setBusy(true);

        try {
            const response = await fetch(
                dbURL() + 'client_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: client_id,
                    salon_id: salon_id,
                    language: language_,
                    token: token
                })
            })

            const json = await response.json();
            setBusy(false);
            Debug('CLIENT-DATA');
            Debug(salon_id);
            Debug(client_id);
            Debug(json);
            
            if (json.ok > 0) {
                setClient(json.client);

                // ******************************************
                // ZAKUPENE PRODUKTY
                // ******************************************
                var tmp = json.products;
                tmp.sort((a, b) => {
                    if (a.date < b.date) {
                        return true;
                    } else {
                        return false;
                    }
                })
                tmp = tmp.map(function (item, index) {
                    item.index = index;
                    return item;
                });

                setProducts(tmp);

                var p = 0;
                setProductsMax(tmp.length);
                tmp = tmp.slice(global.items_max * p, (global.items_max * p) + global.items_max)
                setProductsFiltered(tmp);

                // ******************************************
                // ABSOLVOVANE OSETRENIA
                // ******************************************
                var tmp = json.services;
                tmp.sort((a, b) => {
                    if (a.date < b.date) {
                        return true;
                    } else {
                        return false;
                    }
                })
                tmp = tmp.map(function (item, index) {
                    item.index = index;
                    return item;
                });
                setServices(tmp);

                var p = 0;
                setServicesMax(tmp.length);
                tmp = tmp.slice(global.items_max * p, (global.items_max * p) + global.items_max)
                setServicesFiltered(tmp);

                // ******************************************
                // OBJEDNAVKY Z E-SHOPU
                // ******************************************
                var tmp = json.orders;
                tmp.sort((a, b) => {
                    if (a.date < b.date) {
                        return true;
                    } else {
                        return false;
                    }
                })
                tmp = tmp.map(function (item, index) {
                    item.index = index;
                    return item;
                });
                setOrders(tmp);

                var p = 0;
                setOrdersMax(tmp.length);
                tmp = tmp.slice(global.items_max * p, (global.items_max * p) + global.items_max)
                setOrdersFiltered(tmp);

                // *************************************************
                // POCET POLOZIEK V KOSIKU
                // *************************************************
                setCardQuantity(json.quantity);

            }
        } catch (error) {
            setBusy(false);
            Debug(error);
        }

    };


    /* *******************************************************************************
            ULOZENIE UDAJOV O KLIENTOVI
    ******************************************************************************* */
    const db_client_update = async () => {
        if (isUpdating == false) {
            if (client.id > 0) {
                setUpdating(true);

                try {
                    const response = await fetch(
                        dbURL() + 'client_update', {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            user_id: client.id,
                            name: name,
                            surname: surname,
                            email: email,
                            mobil: mobil,
                            street: street,
                            street_number: streetNumber,
                            psc: psc,
                            town: town
                        })
                    })

                    const json = await response.json();
                    setUpdating(false);
                    Debug(json);

                    if (json.ok > 0) {

                        setEditClient(false);
                        setClient(json.user);
                        SaveClient(json.user);

                        infoRef.current.scrollIntoView({ behavior: 'smooth' });
                    } else {
                        if (json.error > 0) {
                            // EMAIL UZ POUZIVA INI UCET
                            setEmailErr(true);
                            setClientErr(lang.email_error);
                        } else {
                            // CHYBA PRI ZAPISE
                            setClientErr(lang.update_error);
                        }
                    }
                } catch (error) {
                    setClientErr(lang.update_error);
                    setUpdating(false);
                    Debug(error);
                }
            }
        }
    };

    const db_password_new = async () => {
        if (isChanging == false) {
            setChanging(true);
            try {
                const response = await fetch(
                    dbURL() + 'password_new', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        client_id: client.id,
                        password: password1
                    })
                })

                const json = await response.json();
                setChanging(false);
                Debug(json);
                if (json.ok > 0) {
                    setShowChangePassword(false);
                } else {
                    setPasswordErr(lang.password_error);
                }

            } catch (error) {
                Debug(error);
                setChanging(false);
            }
        }
    };


    const BannerTopPress = (value, data) => {
        if (value == 1) {
            // PRIHLASENIE SA UZIVATELA -> presmerovanie na jeho salon
            setSalon(LoadSalon());
            setSettings(LoadSalonSettings());
            setLanguage(LoadLanguage());
            setClient(LoadClient());
            setCardQuantity(LoadCardQuantity());
            setLogedIn(true);
            setBusy(false);
        }

        // AKCIA Z BANNERU
        if (value == 2) {
            // ODHLASIT SA
            navigate('/logout');
        }
    }

    const ChangeMenu = (index) => {
        if (index == 0) {
            setEditClient(false);
        }
        if (index == 1) {
            OrdersRedraw(0);
            setShowProductsDetails(false);
        }
        if (index == 2) {
            ServicesRedraw(0);
        }
        if (index == 3) {
            setShowProductsDetails(false);
            ProductsRedraw(0);
        }

        setMenu(index);
    }

    const GetVouchers = (value) => {
        var divider = lang.voucher_value;
        var sum = Math.floor(value / divider);

        if (sum == 0) {
            return (lang.voucher_none);
        } else {
            return (sum + ' ' + lang.pieces);
        }
    }

    const OpenBonusInfo = () => {
        setShowBonusInfo(true);
    }

    const CloseBonusInfo = () => {
        setShowBonusInfo(false);
    }

    const ServicesRedraw = (page) => {
        var data = services;
        data = data.slice(global.items_max * page, (global.items_max * page) + global.items_max)
        setServicesFiltered(data);
    }

    const PageServices = (page) => {
        setServicesPage(page);
        ServicesRedraw(page);
        GotTop();
    }

    const ProductsRedraw = (page) => {
        var data = products;
        data = data.slice(global.items_max * page, (global.items_max * page) + global.items_max)
        setProductsFiltered(data);
        Debug(data);
    }

    const PageProducts = (page) => {
        setProductsPage(page);
        ProductsRedraw(page);
        GotTop();
    }


    const OrdersRedraw = (page) => {
        var data = orders;
        data = data.slice(global.items_max * page, (global.items_max * page) + global.items_max)
        setOrdersFiltered(data);
        GotTop();
    }

    const PageOrders = (page) => {
        setOrdersPage(page);
        OrdersRedraw(page);
        GotTop();
    }

    const ProductDetail = (index) => {
        setProductsDetails(products[index]);
        var desc = products[index].note_long;
        desc = desc.split('<br>');
        setDescription(desc);
        setShowProductsDetails(true);
    }

    const CloseProduct = () => {
        setShowProductsDetails(false)
    }

    const OrderDetail = () => {

    }

    const OrderProduct = (product) => {
        setProductsDetails(product);
        setShowProductsDetails(true);
    }

    const EditClientData = () => {
        setNameErr(false);
        setSurnameErr(false);
        setMobilErr(false);
        setEmailErr(false);

        setClientErr('');
        setName(client.name);
        setSurname(client.surname);
        setMobil(client.mobil);
        setEmail(client.email);
        setStreet(client.street);
        setStreetNumber(client.street_number)
        setPSC(client.psc);
        setTown(client.town);
        setEditClient(true);
    }

    const CloseEditClientData = () => {
        infoRef.current.scrollIntoView({ behavior: 'smooth' });
        setEditClient(false);
    }

    const SaveData = () => {
        var error = false;

        setNameErr(false);
        setSurnameErr(false);
        setMobilErr(false);
        setEmailErr(false);
        setClientErr('');

        if (IsEmpty(name.trim())) {
            setNameErr(true);
            setClientErr(lang.required);
            error = true;
        }
        if (IsEmpty(surname.trim())) {
            setSurnameErr(true);
            setClientErr(lang.required);
            error = true;
        }
        if (IsEmpty(mobil.trim())) {
            setMobilErr(true);
            setClientErr(lang.required);
            error = true;
        }
        if (IsEmpty(email.trim())) {
            setEmailErr(true);
            setClientErr(lang.required);
            error = true;
        } else if (nonEmail(email.trim())) {
            setEmailErr(true);
            setClientErr(lang.send_err_email);
            error = true;

        }


        if (error == false) {
            db_client_update();
        }
    }

    const BonusInfoPress = (value) => {
        setShowBonusInfo(false);
    }


    const inputMobil = (text) => {
        // input only numbers
        if (/^\d*(?:[+]\d*)?$/.test(text)) {
            setMobil(text);
        }
        if (text == '') {
            setMobil(text);
        }
    }

    const CookiesInfoPress = (value) => {
        setShowCookies(false);
        SaveCookies(true);
    }

    const ChangePassword = () => {
        setPassword1('');
        setPassword2('');
        setPassword1Err(false);
        setPassword2Err(false);
        setPasswordErr('');
        setShowChangePassword(true);
    }

    const PasswordCheck = () => {
        setPassword1Err(false);
        setPassword2Err(false);
        setPasswordErr('');

        var err = false;
        if (password1.trim().length < 8) {
            setPassword1Err(true);
            setPasswordErr(lang.password_new);
            err = true;
        }

        if (err == false) {
            if (password2.trim().length < 8) {
                setPassword2Err(true);
                setPasswordErr(lang.password_new);
                err = true;
            }

            if (err == false) {
                if (password1.trim() != password2.trim()) {
                    setPasswordErr(lang.passwords_not_equal);
                    err = true;
                }
                if (err == false) {
                    db_password_new();
                }
            }
        }
    }

    const Logout = () => {
        navigate('/logout');
    }

    const GotTop = () => {
        setTimeout(() => {
            const element = document.getElementById('id-start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 250);
    }

    return (
        <div id={'id-start'}>
            {isSmall ? null :
                <BannerTop theme={theme} chooseSalon={true} client={client} logged={client == false ? false : true} alias={alias} salon={salon} logedIn={logedIn} language={language} func={BannerTopPress.bind(this)} />
            }
            <NavigationMenu theme={theme} size={() => GetSize()} client={client} logged={client == false ? false : true} showLanguage={false} language={language} logedin={logedIn} alias={alias} salon={salon} index={-1} quantity={cardQuantity} products={productNames} logo={true} />
            <CheckToken alias={alias} func={TokenResult.bind(this)} />

            <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: global.theme_gray }}>
                <div style={{ display: 'flex', justifyContent: 'center', padding: 20, maxWidth: 900 }}>
                    <h2 style={{ letterSpacing: 5 }}>{lang.my_account.toUpperCase()}</h2>
                </div>
            </div>
            {
                // ******************************************************************************************
                // SUB-MENU
                // ******************************************************************************************
            }
            {isLarge == true ?
                <div style={{ display: 'flex', backgroundColor: global.theme_gray, height: 60, justifyContent: 'center', alignItems: 'center' }}>
                    <Button onClick={() => ChangeMenu(0)} style={{ backgroundColor: menu == 0 ? global.theme_lighter : '#00000000', height: 40, width: 250, borderRadius: 20, color: global.theme_dark, textTransform: 'none', fontSize: 16, border: '2px solid ' + global.theme_dark, marginRight: 10 }}>
                        {lang.info}
                    </Button>
                    <Button onClick={() => ChangeMenu(1)} style={{ backgroundColor: menu == 1 ? global.theme_lighter : '#00000000', height: 40, width: 250, borderRadius: 20, color: global.theme_dark, textTransform: 'none', fontSize: 16, border: '2px solid ' + global.theme_dark, marginRight: 10 }}>
                        {lang.orders}
                    </Button>
                    <Button onClick={() => ChangeMenu(2)} style={{ backgroundColor: menu == 2 ? global.theme_lighter : '#00000000', height: 40, width: 250, borderRadius: 20, color: global.theme_dark, textTransform: 'none', fontSize: 16, border: '2px solid ' + global.theme_dark, marginRight: 10 }}>
                        {lang.histrory_services}
                    </Button>
                    <Button onClick={() => ChangeMenu(3)} style={{ backgroundColor: menu == 3 ? global.theme_lighter : '#00000000', height: 40, width: 250, borderRadius: 20, color: global.theme_dark, textTransform: 'none', fontSize: 16, border: '2px solid ' + global.theme_dark }}>
                        {lang.buy_in_salon}
                    </Button>
                </div>
                :
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_gray, justifyContent: 'center', alignItems: 'center' }}>
                        <Button onClick={() => ChangeMenu(0)} style={{ backgroundColor: menu == 0 ? global.theme_lighter : '#00000000', height: 40, width: 250, borderRadius: 20, color: global.theme_dark, textTransform: 'none', fontSize: 14, border: '2px solid ' + global.theme_dark, marginBottom: 10 }}>
                            {lang.info}
                        </Button>
                        <Button onClick={() => ChangeMenu(1)} style={{ backgroundColor: menu == 1 ? global.theme_lighter : '#00000000', height: 40, width: 250, borderRadius: 20, color: global.theme_dark, textTransform: 'none', fontSize: 14, border: '2px solid ' + global.theme_dark, marginBottom: 10 }}>
                            {lang.orders}
                        </Button>
                        <Button onClick={() => ChangeMenu(2)} style={{ backgroundColor: menu == 2 ? global.theme_lighter : '#00000000', height: 40, width: 250, borderRadius: 20, color: global.theme_dark, textTransform: 'none', fontSize: 14, border: '2px solid ' + global.theme_dark, marginBottom: 10 }}>
                            {lang.histrory_services}
                        </Button>
                        <Button onClick={() => ChangeMenu(3)} style={{ backgroundColor: menu == 3 ? global.theme_lighter : '#00000000', height: 40, width: 250, borderRadius: 20, color: global.theme_dark, textTransform: 'none', fontSize: 14, border: '2px solid ' + global.theme_dark, marginBottom: 20 }}>
                            {lang.buy_in_salon}
                        </Button>
                    </div>
                </div>
            }
            {menu == 0 ?
                // ******************************************************************************************
                // INFORMACIE
                // ******************************************************************************************
                client != null ?
                    <div ref={infoRef} style={{ paddingTop: 20 }}>
                        {editClient == false ?
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: isLarge == true ? 'row' : 'column', alignItems: isLarge == true ? 'flex-start' : 'center' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-end', width: '50%', paddingRight: 20, paddingTop: 10 }}>
                                    <h2 style={{ margin: 0 }} >{lang.info}:</h2>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: isLarge == true ? '50%' : '100%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', borderLeft: isLarge ? '2px solid ' + global.theme_dark_gray : '' }}>
                                        <div style={{ display: 'flex', padding: 20, maxWidth: 900, alignItems: 'flex-start', flexDirection: 'column' }}>
                                            <p style={{ margin: 0, fontSize: 20 }} >{lang.user_info.toUpperCase()}:</p>
                                            <p style={{ margin: 0, fontWeight: 'normal', color: global.theme_dark_gray }}>{client.name} {client.surname}</p>
                                            <p style={{ margin: 0, marginTop: 10, color: global.theme_dark_gray }}><FontAwesomeIcon style={{ marginRight: 19 }} icon={faMobileAlt} />{client.mobil}</p>
                                            <p style={{ margin: 0, color: global.theme_dark_gray }}><FontAwesomeIcon style={{ marginRight: 15 }} icon={faEnvelope} />{client.email}</p>
                                            <div style={{ marginTop: 10 }}>
                                                <Button onClick={() => EditClientData()} style={{ margin: 0, padding: 0, color: global.theme_blue, fontSize: 12 }}><FontAwesomeIcon style={{ marginRight: 5, marginTop: -2 }} icon={faEdit} />{lang.edit}</Button>
                                            </div>
                                            <div style={{ marginTop: 10 }}>
                                                <Button onClick={() => ChangePassword()} style={{ margin: 0, padding: 0, color: global.theme_blue, fontSize: 12 }}><FontAwesomeIcon style={{ marginRight: 5, marginTop: -2 }} icon={faEdit} />{lang.change_password}</Button>
                                            </div>
                                            { // ZMENA HESLA
                                                showChangePassword == true ?
                                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', width: 280, backgroundColor: global.theme_white, borderRadius: 20, alignItems: 'center', border: '2px solid ' + global.theme_dark }}>
                                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                                                <IconButton onClick={() => setShowChangePassword(false)} style={{ height: 35 }}>
                                                                    <p style={{ margin: 0, padding: 0 }}>X</p>
                                                                </IconButton>
                                                            </div>
                                                            <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
                                                                <p>{lang.password_change}</p>
                                                                <TextField error={password1Err} type="password" style={{ marginTop: 0, backgroundColor: global.theme_white, width: '100%' }} label={lang.password_new} variant="outlined" value={password1} onInput={e => setPassword1(e.target.value)} />
                                                                <TextField error={password2Err} type="password" style={{ marginTop: 10, backgroundColor: global.theme_white, width: '100%' }} label={lang.password_new_again} variant="outlined" value={password2} onInput={e => setPassword2(e.target.value)} />

                                                                {isChanging == true ?
                                                                    <CircularProgress />
                                                                    : null}

                                                                {passwordErr != '' ?
                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
                                                                        <Alert severity="error">{passwordErr}</Alert>
                                                                    </div>
                                                                    : null}

                                                                <div style={{ marginTop: 20 }}>
                                                                    <Button onClick={() => PasswordCheck()} style={{ backgroundColor: global.theme_black, width: 250 }} variant="contained">{lang.save}</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }

                                        </div>
                                    </div>


                                    <div style={{ display: 'flex', justifyContent: 'flex-start', borderLeft: '2px solid ' + global.theme_dark_gray }}>
                                        <div style={{ display: 'flex', padding: 20, maxWidth: 900, alignItems: 'flex-start', flexDirection: 'column' }}>
                                            <p style={{ margin: 0, fontSize: 20 }} >{lang.user_post.toUpperCase()}:</p>
                                            <p style={{ margin: 0, fontWeight: 'normal', color: global.theme_dark_gray }}>{client.street} {client.street_number}</p>
                                            <p style={{ margin: 0, fontWeight: 'normal', color: global.theme_dark_gray }}>{client.psc} {client.town}</p>
                                        </div>
                                    </div>

                                    <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, marginTop: 30 }}>
                                        <Button onClick={() => Logout()} style={{ ...styles.ButtonDark, backgroundColor: global.theme_lighter, width: 180 }}>
                                            <p style={{ ...styles.TextTiny }}>{lang.logout}</p>
                                            <FontAwesomeIcon style={{ fontSize: 14, color: global.theme_black, marginLeft: 10 }} icon={faAngleRight} />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            : null}

                        {editClient == true ?
                            // ******************************************************************************************
                            // UPRAVA UDAJOV - KLIENT
                            // ******************************************************************************************
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 350, backgroundColor: global.theme_white, borderRadius: 20, alignItems: 'center', border: '2px solid ' + global.theme_dark }}>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                        <IconButton onClick={() => CloseEditClientData()} style={{ height: 35 }}>
                                            <p style={{ margin: 0, padding: 0 }}>X</p>
                                        </IconButton>
                                    </div>
                                    <div style={{ marginTop: 0, paddingBottom: 0 }}>
                                        <img src={logo_circle} height={80} />
                                    </div>
                                    <div style={{ padding: 20 }}>
                                        <p>{lang.user_info}</p>
                                        <TextField error={nameErr} style={{ marginTop: 0, backgroundColor: global.theme_white, width: '100%' }} label={lang.name} variant="outlined" value={name} onInput={e => setName(e.target.value)} />
                                        <TextField error={surnameErr} style={{ marginTop: 10, backgroundColor: global.theme_white, width: '100%' }} label={lang.surname} variant="outlined" value={surname} onInput={e => setSurname(e.target.value)} />
                                        <TextField error={mobilErr} style={{ marginTop: 10, backgroundColor: global.theme_white, width: '100%' }} label={lang.mobil} variant="outlined" value={mobil} onInput={e => inputMobil(e.target.value)} />
                                        <TextField error={emailErr} style={{ marginTop: 10, backgroundColor: global.theme_white, width: '100%' }} label={lang.email} variant="outlined" value={email} onInput={e => setEmail(e.target.value)} />
                                        <p>{lang.user_post}</p>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ width: '70%' }}>
                                                <TextField style={{ marginTop: 0, backgroundColor: global.theme_white, width: '100%' }} label={lang.street} variant="outlined" value={street} onInput={e => setStreet(e.target.value)} />
                                            </div>
                                            <div style={{ width: '30%' }}>
                                                <TextField style={{ marginTop: 0, backgroundColor: global.theme_white, width: '100%' }} label={lang.number} variant="outlined" value={streetNumber} onInput={e => setStreetNumber(e.target.value)} />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                            <div style={{ width: '30%' }}>
                                                <TextField style={{ marginTop: 0, backgroundColor: global.theme_white, width: '100%' }} label={lang.psc} variant="outlined" value={psc} onInput={e => setPSC(e.target.value)} />
                                            </div>
                                            <div style={{ width: '70%' }}>
                                                <TextField style={{ marginTop: 0, backgroundColor: global.theme_white, width: '100%' }} label={lang.town} variant="outlined" value={town} onInput={e => setTown(e.target.value)} />
                                            </div>
                                        </div>

                                        {isUpdating == true ?
                                            <CircularProgress />
                                            : null}

                                        {clientErr != '' ?
                                            <p style={{ color: global.theme_red }}>{clientErr}</p>
                                            : null}

                                        <div style={{ marginTop: 20 }}>
                                            <Button onClick={() => SaveData()} style={{ backgroundColor: global.theme_black, width: 250 }} variant="contained">{lang.save}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}



                        {
                            // ******************************************************************************************
                            // VERNOSTNY SYSTEM
                            // ******************************************************************************************
                        }
                        {salon.score == 1 ?
                            <div style={{ marginTop: 40, backgroundColor: global.theme_gray, paddingTop: 30, paddingBottom: 30 }}>
                                <p style={{ margin: 0, fontSize: 24, fontWeight: 'bold' }} >{lang.bonus_system.toUpperCase()}:</p>
                                <p style={{ margin: 0 }}>{lang.bonus_count}: {client.score}</p>

                                <p style={{ margin: 0, marginTop: 30, fontWeight: 'bold' }}>{lang.voucher_get}:</p>
                                <div style={{ padding: 10 }}>
                                    <img src={language == 0 ? voucher_sk : voucher_cz} width={300 / 1.5} height={134 / 1.5} />
                                </div>
                                <p style={{ margin: 0 }}>{lang.vouchers_all}: {GetVouchers(client.score)}</p>
                                <p style={{ margin: 0, color: global.theme_dark_gray }}>{lang.vouchers_use}</p>
                                <div style={{ marginTop: 20 }}>
                                    <Button onClick={() => OpenBonusInfo()} style={{ margin: 0, padding: 0, color: global.theme_blue, fontSize: 12 }}>{lang.bonus_about}</Button>
                                </div>
                            </div>
                            : null}
                    </div>
                    : null
                : null}

            {menu == 1 ?
                // ******************************************************************************************
                // HISTORIA NAKUPOV - OBJEDNAVKY                    
                // ******************************************************************************************
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', padding: 20, maxWidth: 900 }}>
                        <h3>{lang.orders_products}</h3>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', padding: 20, maxWidth: 900, flexDirection: 'column' }}>
                        < div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}>
                            {
                                ordersFiltered.map(item => {
                                    return (
                                        <div key={item.id} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', padding: 10, width: isLarge == true ? 900 : '100%' }}>
                                            <a onClick={() => OrderDetail()} style={{ padding: 0, display: 'flex', flexDirection: 'row', backgroundColor: global.theme_gray }} >
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 10, justifyContent: 'center', width: '100%' }}>
                                                    <div style={{ display: 'flex', width: '100%', borderBottom: '1px solid ' + global.theme_dark_gray }}>
                                                        <p style={{ fontSize: isLarge == true ? 16 : 14, fontWeight: 'bold', color: GetStatusColor(item.status_id), margin: 0, marginBottom: 5 }}>{GetStatus(lang, item.status_id).toUpperCase()}</p>
                                                    </div>
                                                    <p style={{ fontSize: isLarge == true ? 16 : 14, fontWeight: 'bold', color: global.theme_black, margin: 0, marginBottom: 5, marginTop: 5 }}><FontAwesomeIcon style={{ marginRight: 15 }} icon={faShoppingCart} />{item.label} {item.number}</p>
                                                    <div style={{ paddingLeft: 35 }}>
                                                        <p style={{ fontSize: isLarge == true ? 14 : 12, fontWeight: 'normal', color: global.theme_dark_gray, margin: 0, textAlign: 'left' }}>{lang.date}: {item.datum}</p>
                                                        <p style={{ fontSize: isLarge == true ? 14 : 12, fontWeight: 'normal', color: global.theme_dark_gray, margin: 0, textAlign: 'left' }}>{lang.trade}: {item.trade}</p>
                                                        <p style={{ fontSize: isLarge == true ? 14 : 12, fontWeight: 'normal', color: global.theme_dark_gray, margin: 0, textAlign: 'left' }}>{lang.payment}: {item.payment}</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', backgroundColor: global.theme_gray, paddingLeft: 20 }}>
                                                <p style={{ margin: 0 }}> {lang.product_in_order}</p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'flex-start', padding: 10, backgroundColor: global.theme_gray, width: '100%' }}>
                                                {
                                                    item.products.map(product => {
                                                        return (
                                                            <a onClick={() => OrderProduct(product)} key={product.id} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', padding: 10 }}>
                                                                <img src={GetImageLink(product.image)} width={100} style={{ width: 100, height: 100 }} />
                                                            </a>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', padding: 10, backgroundColor: global.theme_light_gray, width: '100%' }}>
                                                <p style={{ fontSize: isLarge == true ? 16 : 14, fontWeight: 'bold', color: global.theme_red, margin: 0, textAlign: 'left' }}>{lang.sume}: {item.eur} {lang.money}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <PaginationBar color={global.theme_light} max={ordersMax} page={ordersPage} func={PageOrders.bind(this)} />
                    </div>

                    {/* =============================================================================
                            DETAIL PRODUKTU V OBJEDNAVKE
                            ============================================================================= */}
                    <Backdrop open={showProductsDetails} >
                        <Paper style={{ display: 'flex', flexDirection: 'column', width: isSmall ? width - 20 : 400, borderRadius: 20 }}>
                            {productsDetails != false ?
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: global.theme_white, padding: 10 }}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img src={GetImageLink(productsDetails.image)} style={{ width: isSmall ? 280 : 380, height: isSmall ? 280 : 380 }} />
                                    </div>
                                    <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingTop: 10, paddingBottom: 10 }}>
                                        <div style={{ ...styles.Block, width: '96%' }}>
                                            <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{productsDetails.label}</p>
                                            <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, marginTop: 10 }}>{lang.order_quantity}: {productsDetails.quantity}</p>
                                            <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.eur_piece}: {productsDetails.eur} {lang.money}</p>
                                            <p style={{ ...styles.TextTiny, fontWeight: 'bold', marginTop: 10 }}>{lang.sume}: {productsDetails.sum} {lang.money}</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 20, paddingBottom: 10 }}>
                                        <Button onClick={() => setShowProductsDetails(false)} style={{ backgroundColor: global.theme_lighter, height: 40, width: 250, borderRadius: 20, color: global.theme_dark, textTransform: 'none', fontSize: 16, border: '2px solid ' + global.theme_dark, marginRight: 10 }}>
                                            {lang.close}
                                        </Button>
                                    </div>
                                </div>
                                : null}
                        </Paper>
                    </Backdrop>
                </div>



                : null}

            {menu == 2 ?
                // ******************************************************************************************
                // ABSOLVOVANE OSETRENIA
                // ******************************************************************************************
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', padding: 20, maxWidth: 900 }}>
                        <h3>{lang.histrory_services}</h3>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', padding: 20, maxWidth: 900 }}>
                        < div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            {
                                servicesFiltered.map(item => {
                                    return (
                                        <div key={item.id} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', padding: 10, width: isLarge == true ? 900 : '100%' }}>
                                            {item.group_id == 1 ?
                                                // INSTITUT ESTHEDERM
                                                <a style={{ padding: 0, display: 'flex', flexDirection: isLarge ? 'row' : 'column', backgroundColor: global.theme_gray, height: isLarge ? 120 : undefined, borderRadius: isLarge == true ? 60 : 0 }} >

                                                    <div style={{ display: 'flex', justifyContent: 'center', alignSelf: isLarge == true ? 'flex-start' : 'center', width: 120, height: 120, padding: 10 }}>
                                                        <img src={img_service} width={100} style={{ width: 100, height: 100, borderRadius: 50 }} />
                                                    </div>

                                                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: isLarge == true ? 20 : 10, paddingRight: isLarge == true ? 20 : 10, justifyContent: 'center', alignItems: isLarge == true ? 'flex-start' : 'center' }}>
                                                        <p style={{ fontSize: 11, fontWeight: 'normal', color: global.theme_dark_gray, margin: 0 }}>{lang.date}: {item.datum}</p>
                                                        <p style={{ textAlign: isLarge == true ? 'left' : 'center', fontSize: isLarge == true ? 16 : 14, fontWeight: 'bold', color: global.theme_black, margin: 0, marginBottom: 5 }}>{item.label}</p>
                                                        <p style={{ textAlign: isLarge == true ? 'left' : 'center', fontSize: isLarge == true ? 14 : 12, fontWeight: 'normal', color: global.theme_dark_gray, margin: 0 }}>{item.description}</p>
                                                    </div>
                                                </a>
                                                :
                                                // MOJE OSLUZBY
                                                <a style={{ paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'row', backgroundColor: global.theme_gray, height: 120, borderRadius: isLarge == true ? 60 : 0 }} >

                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: isLarge == true ? 20 : 0, paddingRight: isLarge == true ? 20 : 15, justifyContent: 'center', width: '100%' }}>
                                                        <p style={{ fontSize: isLarge == true ? 16 : 14, fontWeight: 'bold', color: global.theme_black, margin: 0, marginBottom: 5 }}>{item.label}</p>
                                                        <p style={{ fontSize: isLarge == true ? 14 : 12, fontWeight: 'normal', color: global.theme_dark_gray, margin: 0, textAlign: 'left' }}>{item.description}</p>
                                                    </div>
                                                </a>
                                            }
                                        </div>
                                    )
                                })
                            }
                            <PaginationBar color={global.theme_light} max={servicesMax} page={servicesPage} func={PageServices.bind(this)} />

                        </div>
                    </div>

                </div>
                : null}

            {menu == 3 ?
                // ******************************************************************************************
                // ZAKUPENE PRODUKTY
                // ******************************************************************************************
                showProductsDetails == false ?
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: 20, maxWidth: 900 }}>
                            <h3>{lang.histrory_products}</h3>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20, maxWidth: 900, flexDirection: 'column' }}>
                            < div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}>
                                {
                                    productsFiltered.map(item => {
                                        return (
                                            <div key={item.id} style={{ display: 'flex', margin: 5, width: isLarge == true ? 360 : 300, height: 430 }}>
                                                <a onClick={() => ProductDetail(item.index)} style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: global.theme_gray, paddingTop: 20 }} >
                                                    <div style={{ display: 'flex', justifyContent: 'center', width: 300, height: 300 }}>
                                                        <img src={GetImageLink(item.image)} style={{ width: 300, height: 300 }} />
                                                    </div>

                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10 }}>
                                                        <p style={{ fontSize: 11, fontWeight: 'normal', color: global.theme_dark_gray, margin: 0 }}>{item.code}</p>
                                                        <p style={{ fontSize: isLarge == true ? 16 : 14, fontWeight: 'bold', color: global.theme_black, margin: 0, marginBottom: 5 }}>{item.label}</p>
                                                        <p style={{ fontSize: 11, fontWeight: 'normal', color: global.theme_dark_gray, margin: 0 }}>{lang.date}: {item.datum}</p>
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <PaginationBar color={global.theme_light} max={productsMax} page={productsPage} func={PageProducts.bind(this)} />
                        </div>

                    </div>
                    :
                    // DETAIL PRODUKTU
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: global.theme_white, padding: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={GetImageLink(productsDetails.image)} style={{ width: 380, height: 380 }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20, maxWidth: 900, backgroundColor: global.theme_gray }}>
                            <p style={{ fontSize: 11, fontWeight: 'normal', color: global.theme_dark_gray, margin: 3 }}>{lang.code}: {productsDetails.code}</p>
                            <p style={{ fontWeight: 'normal', color: global.theme_dark_gray, margin: 3 }}>{productsDetails.section} {productsDetails.section != '' ? '-' : ''} {productsDetails.category}</p>
                            <h3 style={{ fontWeight: 'bold' }}>{productsDetails.label}</h3>
                            <p style={{ fontWeight: 'normal', color: global.theme_dark_gray }}>{productsDetails.note_short}</p>
                            {description.map((data, index) => {
                                return (
                                    <p key={index} style={{ fontWeight: 'normal', textAlign: 'left' }}>{data}</p>
                                )
                            })}
                            {productsDetails.details != '' ?
                                <p style={{ fontWeight: 'normal', color: global.theme_dark_gray }}>{lang.details}: {productsDetails.details}</p>
                                : null}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 20, paddingBottom: 40 }}>
                            <Button onClick={() => setShowProductsDetails(false)} style={{ backgroundColor: global.theme_lighter, height: 40, width: 250, borderRadius: 20, color: global.theme_dark, textTransform: 'none', fontSize: 16, border: '2px solid ' + global.theme_dark, marginRight: 10 }}>
                                {lang.back}
                            </Button>
                        </div>
                    </div>
                : null}


            {
                // ******************************************************************************************
                // INFORMACIE O VERNOSTNOM SYSTEME
                // ******************************************************************************************
                showBonusInfo == true ?
                    <BonusInfo lang={lang} language={language} func={BonusInfoPress.bind(this)} />
                    : null
            }

            {showCookies == true ?
                <CookiesInfo lang={lang} salon={salon} func={CookiesInfoPress.bind(this)} />
                : null}

            <Footer language={language} islogged={logedIn} />

        </div >
    )

}
